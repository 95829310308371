<template>
  <a-dropdown>
    <span class="action global-lang">
      <a-icon type="global" style="font-size: 16px"/>
    </span>
    <a-menu
      v-model="defaultLanguage"
      slot="overlay"
      style="width: 150px;"
      @click="SwitchLang">
      <a-menu-item v-for="item in languageLabels" :key="item.code">
        <a rel="noopener noreferrer">
          <span role="img" :aria-label="item.label">{{ item.shortName }}</span> {{ item.label }}
        </a>
      </a-menu-item>
      <!-- <a-menu-item key="zh-TW">
        <a rel="noopener noreferrer">
          <span role="img" aria-label="繁体中文">🇭🇰</span> 繁体中文
        </a>
      </a-menu-item>
      <a-menu-item key="en-US">
        <a rel="noopener noreferrer">
          <span role="img" aria-label="English">🇬🇧</span> English
        </a>
      </a-menu-item>
      <a-menu-item key="pt-BR">
        <a rel="noopener noreferrer">
          <span role="img" aria-label="Português">🇧🇷</span> Português
        </a>
      </a-menu-item> -->
    </a-menu>
  </a-dropdown>
</template>

<script>
// import { mixin as langMixin } from '@/store/i18n-mixin'
import lang, { languageLabels } from '../../i18n/lang/'
import { mapActions, mapGetters } from 'vuex';
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

export default {
    name: 'LangSelect',
    // mixins: [langMixin],
    data () {
        return {
            languageLabels,
            defaultLanguage: ['zh-CN']
        }
    },
    computed: {
        ...mapGetters('language', ['languageStorage']),
    },
    created () {
      this.languageStorage && (this.defaultLanguage = [this.languageStorage]);
    },
    methods: {
        ...mapActions('language', ['setLanguage']),

        SwitchLang ({ key }) {
            this.$i18n.locale = key;
            this.defaultLanguage = [key];
            // console.log("key",key)
            this.setLanguage(key);
            const to = this.$route;
            // console.log("to",to)
            setDocumentTitle(`${this.languageStorage == lang.zhCN ?  to.meta.title : to.meta.enTitle} - ${domTitle}`)
        }
    }
}
</script>
