<template>
  <a-layout :class="['layout', device]">
    <!-- SideMenu -->
    <a-drawer
      v-if="isMobile()"
      placement="left"
      :wrapClassName="`drawer-sider ${navTheme}`"
      :closable="false"
      :visible="collapsed"
      @close="drawerClose"
    >
      <side-menu
        mode="inline"
        :menus="menus"
        :theme="navTheme"
        :collapsed="false"
        :collapsible="true"
        @menuSelect="menuSelect"
      ></side-menu>
    </a-drawer>

    <side-menu
      v-else-if="isSideMenu()"
      mode="inline"
      :menus="menus"
      :theme="navTheme"
      :collapsed="collapsed"
      :collapsible="true"
    ></side-menu>

    <a-layout :class="[layoutMode, `content-width-${contentWidth}`]" :style="{ paddingLeft: contentPaddingLeft, minHeight: '100vh' }">
      <!-- layout header -->
      <global-header
        :mode="layoutMode"
        :menus="menus"
        :theme="navTheme"
        :collapsed="collapsed"
        :device="device"
        @toggle="toggle"
      />

      <!-- layout content -->
      <a-layout-content :style="{ height: '100%', margin: '24px 24px 0', paddingTop: fixedHeader ? '64px' : '0' }">
        <multi-tab v-if="multiTab"></multi-tab>
        <transition name="page-transition">
          <route-view />
        </transition>
      </a-layout-content>

      <!-- layout footer -->
      <a-layout-footer>
        <global-footer />
      </a-layout-footer>

      <!-- Setting Drawer (show in development mode) -->
      <setting-drawer v-if="!production"></setting-drawer>
    </a-layout>
  </a-layout>

</template>

<script>
import { triggerWindowResizeEvent } from '@/utils/util'
import { mapGetters, mapActions } from 'vuex'
import { mixin, mixinDevice } from '@/utils/mixin'
import config from '@/config/defaultSettings'

import RouteView from './RouteView'
import SideMenu from '@/components/Menu/SideMenu'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
import SettingDrawer from '@/components/SettingDrawer'
import { asyncRouterMap } from '@/config/router.config.js'
import lang from '../i18n/lang/'

export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: {
    RouteView,
    SideMenu,
    GlobalHeader,
    GlobalFooter,
    SettingDrawer
  },
  data () {
    return {
      production: config.production,
      multiTab: config.multiTab,
      collapsed: false,
      menus: [],
    }
  },
  computed: {
    ...mapGetters({
        mainMenu: 'menus',
    }),
    ...mapGetters('language', ['languageStorage']),
    contentPaddingLeft () {
      if (!this.fixSidebar || this.isMobile()) {
        return '0'
      }
      if (this.sidebarOpened) {
        return '256px'
      }
      return '80px'
    }
  },
  watch: {
    sidebarOpened (val) {
      this.collapsed = !val
    },
    // mainMenu: {
    //   handler (menus) {
    //     this.menus = this.tranformRoutes(menus);
    //   },
    //   immediate: true,
    // },
    languageStorage: {
      handler (lang) {
        const title = this.$route.meta && this.$route.meta.title;
        // console.log("title",title)
        this.menus = this.tranformRoutes(this.mainMenu, 2);
        // console.log("this.menus",this.menus)
      },
      immediate: true,
    }
  },
  created () {
    window.menus = this;
    // this.menus = asyncRouterMap.find((item) => item.path === '/').children;
    this.menus = this.tranformRoutes(this.mainMenu, 2);
    this.collapsed = !this.sidebarOpened
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    ...mapActions(['setSidebar']),
    toggle () {
      this.collapsed = !this.collapsed
      // this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    tranformRoutes (routes, round) {
      
      return routes.map(item => {
        let title=""
        if(this.languageStorage == lang.zhCN){
          title=item.nameCn
        }
         if(this.languageStorage == lang.enUS){
          title=item.nameEn
        }
         if(this.languageStorage == lang.esUS){
          title=item.nameEs
        }
         if(this.languageStorage == lang.frUS){
          title=item.nameFr
        }
         if(this.languageStorage == lang.arUS){
          title=item.nameAr
        }
        return {
          _routes: item,
          name: item.nameCn,
          path: item.menuPath,
          meta: {
            // title: this.languageStorage == lang.zhCN ? item.nameCn : item.nameEn,
            title,
            icon: (item.menuIcon && item.menuIcon.length > 0) ? item.menuIcon : undefined,
          },
          children: item.children && item.children.length && round > 1 && this.tranformRoutes(item.children, round - 1)
        }
      })
    },
    paddingCalc () {
      let left = ''
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '256px' : '80px'
      } else {
        left = (this.isMobile() && '0') || ((this.fixSidebar && '80px') || '0')
      }
      return left
    },
    menuSelect () {
    },
    drawerClose () {
      this.collapsed = false
    }
  }
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */

.page-transition-enter {
  opacity: 0;
}

.page-transition-leave-active {
  opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
