// eslint-disable-next-line
import { UserLayout, BasicLayout, RouteView, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'
import i18n from '../i18n/'

export const asyncRouterMap = [

  {
    path: '/',
    name: 'home',
    component: BasicLayout,
    meta: { title: 'home.title'},
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        name: 'homepage',
        component: () => import('../views/dashboard/Homepage.vue'),
        meta: { title: '首页',keepAlive: true }
      },
      //客户管理
      {
        path: '/customer',
        component: PageView,
        meta: { title: 'customer.title', icon: 'user'},
        children: [
          {
            path: '/customer/list',
            name: 'customerList',
            component: () => import('../views/customer/CustomerList.vue'),
            meta: { title: 'customer.customerList', keepAlive: true }
          },
          {
            path: '/customer/detail',
            name: 'customerDetail',
            component: () => import('../views/customer/CustomerDetail.vue'),
            meta: { title: 'customer.customerDetail', keepAlive: true }
          },
          {
            path: '/customer/custOperationCount',
            name: 'customerDetail',
            component: () => import('../views/customer/CustOperationCount.vue'),
            meta: { title: 'customer.customerDetail', keepAlive: true }
          },
          {
            path: '/customer/custOperationAllCount',
            name: 'customerDetail',
            component: () => import('../views/customer/CustOperationAllCount.vue'),
            meta: { title: 'customer.customerDetail', keepAlive: true }
          },
          {
            path: '/customer/Custleaving',
            name: 'customerDetail',
            component: () => import('../views/customer/Custleaving.vue'),
            meta: { title: 'customer.Custleaving', keepAlive: true }
          },
          {
            path: '/customer/CustleavingDetail',
            name: 'CustleavingDetail',
            component: () => import('../views/customer/CustleavingDetail.vue'),
            meta: { title: 'customer.CustleavingDetail', keepAlive: true }
          },
        ]
      },
      //订单管理
      {
        path: '/order',
        component: PageView,
        meta: { title: 'order.title'},
        children: [
          {
            path: '/order/allorderlist',
            name: 'allorderlist',
            component: () => import('../views/order/AllOrderList.vue'),
            meta: { title: 'order.allTitle', keepAlive: true }
          },
          {
            path: '/order/loanorderlist',
            name: 'loanorderlist',
            component: () => import('../views/order/LoanOrderlist.vue'),
            meta: { title: 'order.loanTitle', keepAlive: true }
          },
          {
            path: '/order/pendingorderlist',
            name: 'pendingorderlist',
            component: () => import('../views/order/PendingOrderlist.vue'),
            meta: { title: 'order.pendingTitle', keepAlive: true }
          },
          {
            path: '/order/overduelist',
            name: 'overduelist',
            component: () => import('../views/order/Overduelist.vue'),
            meta: { title: 'order.overdueTitle', keepAlive: true }
          },
          {
            path: '/order/repayReport',
            name: 'repayReport',
            component: () => import('../views/order/repayReport.vue'),
            meta: { title: 'order.repayReportTitle', keepAlive: true }
          },
          {
            path: '/order/transformingDataExcel',
            name: 'transformingDataExport',
            component: () => import('../views/order/TransformingDataExcel.vue'),
            meta: { title: 'order.transformingDataExportTitle', keepAlive: true }
          },
          {
            path: '/order/repayOverDueReport',
            name: 'overdueReport',
            component: () => import('../views/order/repayOverDueReport.vue'),
            meta: { title: 'order.repayOverDueReportTitle', keepAlive: true }
          },
          {
            path: '/platformOrder/partRepaylist',
            name: 'partRepaylist',
            component: () => import('../views/platformOrder/partRepaylist.vue'),
            meta: { title: 'order.partRepaylistTitle', keepAlive: true }
          },
          // ,
          // {
          //   path: '/platformOrder/order/loanOrderlist',
          //   name: 'platformLoanOrderlist',
          //   component: () => import('../views/platformOrder/LoanOrderlist.vue'),
          //   meta: { title: 'order.loanTitle', keepAlive: true }
          // }
        ]
      },
      //运营管理
      {
        path: '/operation',
        component: PageView,
        meta: { title: '运营管理'},
        children: [
          {
            path: '/operation/account',
            name: 'accountManagement',
            component: () => import('../views/operation/AccountList.vue'),
            meta: { title: '账户管理', keepAlive: true }
          },
          {
            path: '/operation/product',
            name: 'productManagement',
            component: () => import('../views/operation/ProductManagement.vue'),
            meta: { title: '产品管理', keepAlive: true }
          },
          {
            path: '/operation/productItem',
            name: 'ProductItemList',
            component: () => import('../views/operation/ProductItemList.vue'),
            meta: { title: '产品详情', keepAlive: true }
          }
        ]
      },
      //审批管理
      {
        path: '/approval',
        component: PageView,
        meta: { title: 'approval.title'},
        children: [
          {
            path: '/approval/allapprovaltask',
            name: 'allapprovaltask',
            component: () => import('../views/approval/AllApprovalTask.vue'),
            meta: { title: 'approval.allTitle', keepAlive: true }
          },
          {
            path: '/approval/myapprovaltasks',
            name: 'myapprovaltasks',
            component: () => import('../views/approval/MyApprovalTasks.vue'),
            meta: { title: 'approval.mytitle', keepAlive: true }
          },
          {
            path: '/approval/historyapprovaltasks',
            name: 'historyapprovaltasks',
            component: () => import('../views/approval/HistoryApprovalTasks.vue'),
            meta: { title: 'approval.historytitle', keepAlive: true }
          },
          {
            path: '/approval/approvalperformance',
            name: 'approval',
            component: () => import('../views/approval/ApprovalPerformance.vue'),
            meta: { title: 'approval.performancetitle', keepAlive: true }
          }
        ]
      },
      //风控管理
      {
        path: '/risk',
        component: PageView,
        meta: { title: 'collection.title'},
        children: [
          {
            path: '/risk/riskrulelist',
            name: 'riskRuleList',
            component: () => import('../views/risk/riskRuleList.vue'),
            meta: { title: '风控规则列表', keepAlive: true }
          },{
            path: '/risk/statistics',
            name: 'riskStatistics',
            component: () => import('../views/risk/riskStatistics.vue'),
            meta: { title: '决策统计查询', keepAlive: true }
          },
          {
            path: '/risk/variable',
            name: 'riskVariable',
            component: () => import('../views/risk/riskVariable.vue'),
            meta: { title: '风控变量管理', keepAlive: true }
          }
        ]
      },
      //催收管理
      {
        path: '/collection',
        component: PageView,
        meta: { title: 'collection.title'},
        children: [
          {
            path: '/collection/allcollectiontask',
            name: 'allcollectiontask',
            component: () => import('../views/collection/AllCollectionTask.vue'),
            meta: { title: 'collection.allTitle', keepAlive: true }
          },
          {
            path: '/collection/mycollectiontasks',
            name: 'mycollectiontasks',
            component: () => import('../views/collection/MyCollectionTask.vue'),
            meta: { title: 'collection.mytitle', keepAlive: true }
          },
          {
            path: '/collection/historycollectiontasks',
            name: 'historycollectiontasks',
            component: () => import('../views/collection/HistoryCollectionTask.vue'),
            meta: { title: 'collection.historytitle', keepAlive: true }
          },
          {
            path: '/collection/collectionperformance',
            name: 'collectionPerformance',
            component: () => import('../views/collection/CollectionPerformance.vue'),
            meta: { title: 'collection.performancetitle', keepAlive: true }
          }
        ]
      },
      // 系统管理
      {
        path: '/system',
        name: 'system',
        redirect: '/system/menu/list',
        component: PageView,
        meta: { title: 'system.title', icon: 'setting', permission: [ 'user' ] },
        children: [
          {
            path: '/system/menu/list',
            name: 'menuList',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import('../views/system/MenuList.vue'),
            meta: { title: 'system.menuList.title', keepAlive: true, permission: [ 'user' ] }
          },
          {
            path: '/system/role/list',
            name: 'roleList',
            component: () => import('../views/system/RoleList.vue'),
            meta: { title: 'system.roleList.title', keepAlive: true, permission: [ 'role' ] }
          },
          {
            path: '/system/user/list',
            name: 'userList',
            component: () => import('../views/system/UserList.vue'),
            meta: { title: 'system.userList.title', keepAlive: true, permission: [ 'user' ] }
          },
          {
            path: '/system/user/collist',
            name: 'colUserList',
            component: () => import('../views/system/ColUserList.vue'),
            meta: { title: 'system.userList.title', keepAlive: true, permission: [ 'user' ] }
          },
          {
            path: '/system/datadict',
            name: 'datadict',
            component: () => import('../views/system/Datadict.vue'),
            meta: { title: '数据字典', keepAlive: true }
          },
          {
            path: '/system/appList',
            name: 'appList',
            component: () => import('../views/system/AppList.vue'),
            meta: { title: '应用管理', keepAlive: true }
          }
        ]
      }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]
