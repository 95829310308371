export default {
    zh: {
        title: '风控管理',
        bankCardCheck: '银行卡认证',
        mobile: '手机号',
        bankAccountNo: '银行账户号',
        ifscCode: 'IFSC CODE',
    },
    en: {
        title: 'Risk Management',
        bankCardCheck: 'Bank card verification',
        mobile: 'Mobile',
        bankAccountNo: 'BankAccount NO',
        ifscCode: 'IFSC CODE',
    }
}