import api from './index'
import { axios } from '@/utils/request'

export function getUserList (parameter) {
  return axios({
    url: '/user/list',
    method: 'post',
    data: parameter
  })
}

export function getColUserList (parameter) {
  return axios({
    url: '/user/colList',
    method: 'post',
    data: parameter
  })
}

export function getAdminColUserList (parameter) {
  return axios({
    url: '/user/colAdminList',
    method: 'post',
    data: parameter
  })
}


export function getRoleList (parameter) {
  return axios({
    url: '/role/list',
    method: 'post',
    data: parameter
  })
}

export function addUser (parameter = {}) {
  return axios({
    url: '/user/add',
    method: 'post',
    data: parameter
  })
}

export function addColUser (parameter = {}) {
  return axios({
    url: '/user/addCol',
    method: 'post',
    data: parameter
  })
}

export function addAdminColUser (parameter = {}) {
  return axios({
    url: '/user/addAdminCol',
    method: 'post',
    data: parameter
  })
}

export function editColUser (parameter = {}) {
  return axios({
    url: '/user/editCol',
    method: 'post',
    data: parameter
  })
}

export function editAdminColUser (parameter = {}) {
  return axios({
    url: '/user/editAdminCol',
    method: 'post',
    data: parameter
  })
}


export function editUser (parameter = {}) {
  return axios({
    url: '/user/edit',
    method: 'post',
    data: parameter
  })
}
export function deleteUser (parameter) {
  return axios({
    url: '/user/delete',
    method: 'post',
    data: parameter
  })
}
export function getAllRoleList (parameter = {}) {
  return axios({
    url: '/role/selectList',
    method: 'post',
    data: parameter
  })
}
export function getAppAllRoleList (parameter = {}) {
  return axios({
    url: '/role/selectAppList',
    method: 'post',
    data: parameter
  })
}
export function addRole (parameter = {}) {
  return axios({
    url: '/role/add',
    method: 'post',
    data: parameter
  })
}

export function editRole (parameter = {}) {
  return axios({
    url: '/role/edit',
    method: 'post',
    data: parameter
  })
}
export function getRolePermission (parameter = {}) {
  return axios({
    url: '/role/permissions',
    method: 'post',
    data: parameter
  })
}
export function setRolePermission (parameter = {}) {
  return axios({
    url: '/role/grant',
    method: 'post',
    data: parameter
  })
}

export function getMenuListTree (parameter = {}) {
  return axios({
    url: '/menu/listTree',
    method: 'post',
    data: parameter
  })
}
export function addMenu (parameter = {}) {
  return axios({
    url: '/menu/add',
    method: 'post',
    data: parameter
  })
}
export function editMenu (parameter = {}) {
  return axios({
    url: '/menu/edit',
    method: 'post',
    data: parameter
  })
}
export function deleteMenu (parameter = {}) {
  return axios({
    url: '/menu/delete',
    method: 'post',
    data: parameter
  })
}
// 获取字典列表
export function getDict (parameter = {}) {
  return axios({
    url: '/dict/list',
    method: 'post',
    data: parameter
  })
}
// 添加字典
export function addDict (parameter = {}) {
  return axios({
    url: '/dict/add',
    method: 'post',
    data: parameter
  })
}

// 编辑字典
export function editDict (parameter = {}) {
  return axios({
    url: '/dict/edit',
    method: 'post',
    data: parameter
  })
}

// 删除字典
export function deleteDict (parameter = {}) {
  return axios({
    url: '/dict/delete',
    method: 'post',
    data: parameter
  })
}

// 获取字典明细列表
export function getDictItem (parameter = {}) {
  return axios({
    url: '/dictItem/list',
    method: 'post',
    data: parameter
  })
}

// 添加字典明细
export function addDictItem (parameter = {}) {
  return axios({
    url: '/dictItem/add',
    method: 'post',
    data: parameter
  })
}

// 编辑字典明细
export function editDictItem (parameter = {}) {
  return axios({
    url: '/dictItem/edit',
    method: 'post',
    data: parameter
  })
}

// 编辑字典明细
export function deleteDictItem (parameter = {}) {
  return axios({
    url: '/dictItem/delete',
    method: 'post',
    data: parameter
  })
}

// 清楚缓存
export function clearDictCache (parameter = {}) {
  return axios({
    url: '/dict/refresh',
    method: 'post',
    data: parameter
  })
}

// 应用管理
export function getAppList (parameter = {}) {
  return axios({
    url: '/app/list',
    method: 'post',
    data: parameter
  })
}
export function addApp (parameter = {}) {
  return axios({
    url: '/app/add',
    method: 'post',
    data: parameter
  })
}
export function editApp (parameter = {}) {
  return axios({
    url: '/app/edit',
    method: 'post',
    data: parameter
  })
}
export function deleteApp (parameter = {}) {
  return axios({
    url: '/app/delete',
    method: 'post',
    data: parameter
  })
}

// 应用管理-员工管理
export function getEmployee (parameter = {}) {
  return axios({
    url: '/appUser/list',
    method: 'post',
    data: parameter
  })
}
export function addEmployee (parameter = {}) {
  return axios({
    url: '/appUser/add',
    method: 'post',
    data: parameter
  })
}
export function editEmployee (parameter = {}) {
  return axios({
    url: '/appUser/edit',
    method: 'post',
    data: parameter
  })
}
export function deleteEmployee (parameter = {}) {
  return axios({
    url: '/appUser/delete',
    method: 'post',
    data: parameter
  })
}

export function getAppSettingList (parameter = {}) {
  return axios({
    url: '/app/listAppSetting',
    method: 'post',
    data: parameter
  })
}

export function editAppSetting (parameter = {}) {
  return axios({
    url: '/app/editAppSetting',
    method: 'post',
    data: parameter
  })
}

export function addAppSetting (parameter = {}) {
  return axios({
    url: '/app/addAppSetting',
    method: 'post',
    data: parameter
  })
}

export function deleteAppSetting (parameter = {}) {
  return axios({
    url: '/app/deleteAppSetting',
    method: 'post',
    data: parameter
  })
}

export function getAppStrategyidList (parameter = {}) {
  return axios({
    url: '/app/listAppStrategyid',
    method: 'post',
    data: parameter
  })
}
export function addAppStrategyid (parameter = {}) {
  return axios({
    url: '/app/addAppStrategyid',
    method: 'post',
    data: parameter
  })
}

export function getDurationsByAppParentId (parentId) {
  return axios({
    url: '/product/getDurationsByAppParentId?parentId=' + parentId,
    method: 'get',
  })
}

export function addAppSub (parameter = {}) {
  return axios({
    url: '/app/addAppSub',
    method: 'post',
    data: parameter
  })
}

export function getAllSubAppById (id) {
  return axios({
    url: '/app/getAllSubAppById?id=' + id,
    method: 'get',
  })
}

export function userBlackList (parameter = {}) {
  return axios({
    url: '/user/userBlackList',
    method: 'post',
    data: parameter
  })
}

// 应用管理
export function getAppSettingCpList (parameter = {}) {
  return axios({
    url: '/app/setting/queryList',
    method: 'post',
    data: parameter
  })
}


export function editCpAppSetting (parameter = {}) {
  return axios({
    url: '/app/setting/editSetting',
    method: 'post',
    data: parameter
  })
}

export function addCpSetting (parameter = {}) {
  return axios({
    url: '/app/setting/addSetting',
    method: 'post',
    data: parameter
  })
}





