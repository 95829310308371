export default {
    zh: {
        title: '客户管理',
        customerList: '客户列表',
        customerDetail: '客户详情',
        mobile: '手机号',
        names: '名字',
        surnames: '姓氏',
        curpType: '证件类型',
        curp: '证件',
        appName: 'APP名字',
        email: '邮箱',
        description: '描述',
        custName: '客户姓名',
        AadhaarName: 'Aadhaar姓名',
        AadhaarAddress: 'Aadhaar地址',
        AadhaarYears: 'Aadhaar年份',
        AadhaarDate: 'Aadhaar日期',
        AadhaarNo: 'Aadhaar号',
        panName: 'Pan姓名',
        panNo: 'Pan卡号',
        panAddress: 'Pan地址',
        panYears: 'Pan年份',
        panDate: 'Pan日期',
        bankAccountName: '银行账户姓名',
        bankAccountNo: '收款银行账户号',
        collectType: '收款方式',
        ifscCode: 'IFSC CODE',
        maritalStatus: '婚姻状况',
        spouseName: '配偶名称',
        educationLevel: '教育程度',
        monthlyHouseholdIncome: '家庭月收入',
        personalMonthlyIncome: '个人月收入',
        housingType: '住房类型',
        residentialAddress: '居住地址',
        residentialAddressPIN: '居住地址PIN',
        workType: '工作类型',
        workingAddress: '工作地址',
        workingAddressPIN: '工作地址PIN',
        companyName: '公司名称',
        workIndustry: '工作行业',
        workDuty: '工作职务',
        workPosition: '工作职位',
        workYear: '工作年限',
        payMethod: '发薪方式',

        contactType: '联系人类型',
        contactRelationship: '联系人关系',
        contactName: '联系人名称',
        contactPhone: '联系人手机号',
        firstContact: '第一联系人',
        secondContact: '第二联系人',

        aadhaarFront: 'Aadhaar卡正面',
        aadhaarBack: 'Aadhaar卡反面',
        panFront: 'PAN卡照片',
        facePhotoList: '活体检测照片',

        createTime: '注册时间',
        reborrowFlag: '复借标识',
        customerBasicInfo: '客户基本信息',
        customerDetailInfo: '客户详细信息',
        customerLinkInfo: '客户联系人信息',
        customerAadhaarInfo: '客户Aadhaar卡OCR信息',
        customerPanInfo: '客户Pan卡OCR信息',
        customerImageInfo: '客户影像资料',
        age: '年龄',
        channel: '渠道',
        productName: '产品名称',
        epochScore: 'Epoch分',
        modelScore: '模型分',
        customerRiskInfo: '客户风控信息',
        panCardLiveScore: 'PAN卡人脸相似分',
        aadhaarCardLiveScore: 'AADHAAR卡人脸相似分',
        transformingDataExcel: '转化数据导出',
        campaignDataExcel: 'campaign导出',
        channelDataExcel: 'channel导出',
        publisherDataExcel: 'publisher导出',
        setNameDataExcel: 'set_name导出',
        adnameDataExcel: 'ad_name导出',
        dollar3pDataExcel: 'dollar_3p导出',

        payoutId: '放款渠道ID',
        fullName: '客户名称',
        bankName: '收款银行名称',
        excelList: '催收函图片',
        orderImages: '支付凭证',

        companyPhone: '公司电话',
        monthlyIncomeScope: '月收入',
    },
    en: {
        title: 'Customer Management',
        customerList: 'Customer List',
        customerDetail: 'Customer Detail',
        mobile: 'Phone number',
        names: 'name',
        surnames: 'surname',
        curpType: 'curpType',
        curp: 'curp',
        appName: 'App Name',
        custName: 'cust name',
        AadhaarName: 'Aadhaar Name',
        AadhaarAddress: 'Aadhaar Address',
        AadhaarYears: 'Aadhaar Years',
        AadhaarDate: 'Aadhaar Date',
        AadhaarNo: 'Aadhaar Number',
        panName: 'Pan Name',
        panNo: 'Pan Number',
        email: 'Email',
        panAddress: 'Pan Address',
        panYears: 'Pan Years',
        panDate: 'Pan Date',
        bankAccountName: 'BankAccount Name',
        bankAccountNo: 'bank card',
        collectType: 'collect type',
        ifscCode: 'IFSC CODE',
        maritalStatus: 'Marital Status',
        spouseName: 'Spouse Name',
        educationLevel: 'Education Level',
        monthlyHouseholdIncome: 'Monthly Household Income',
        personalMonthlyIncome: 'Personal Monthly Income',
        housingType: 'Housing Type',
        residentialAddress: 'Dirección de residencia',
        residentialAddressPIN: 'Residential Address PIN',
        workType: 'Work Type',
        workingAddress: 'Working Address',
        workingAddressPIN: 'Working Address PIN',
        companyName: 'Company Name',
        workIndustry: 'Work Industry',
        workDuty: 'Work Duty',
        workPosition: 'Work Position',
        workYear: 'Work Year',
        payMethod: 'Pay Method',

        contactType: 'Type',
        contactRelationship: 'Relationship',
        contactName: 'Contact name',
        contactPhone: 'Phone number',
        firstContact: 'First Contact',
        secondContact: 'Second Contact',

        aadhaarFront: 'Aadhaar Front',
        aadhaarBack: 'Aadhaar Back',
        panFront: 'PAN Front',
        facePhotoList: 'Face Photo',

        createTime: 'Fecha de creación',
        reborrowFlag: 'Reborrow Flag',
        customerBasicInfo: 'Customers Basic Information',
        customerDetailInfo: 'Customers Detail Information',
        customerLinkInfo: 'Customers Contact Information',
        customerAadhaarInfo: 'Customers Aadhaar‘s OCR Information',
        customerPanInfo: 'Customers Pan‘s OCR Information',
        customerImageInfo: 'Customer Image Data',
        age: 'Edad',
        channel: 'Channel',
        productName: 'Product Name',
        epochScore: 'Epoch Score',
        modelScore: 'Model Score',
        customerRiskInfo: 'Customer Risk Info',
        panCardLiveScore: 'PAN CARD FACE SCORE',
        aadhaarCardLiveScore: 'AADHAAR FACE SCORE',

        stpAccount: 'stp',
        fullName: 'Full name',
        bankName: 'Bank Name',
        excelList: ' Download loan information images ',
        orderImages: 'Up payment voucher ',

        companyPhone: 'Company Office Number',
        monthlyIncomeScope: 'monthly income',
        description: 'description',
    },
    es: {
        title: 'Customer Management',
        customerList: 'Customer List',
        customerDetail: 'Customer Detail',
        mobile: 'Phone number',
        names: 'Nombre',
        surnames: 'Apellido',
        curpType: 'curpType',
        curp: 'Documento de identidad',
        appName: 'App Name',
        custName: 'cust name',
        AadhaarName: 'Aadhaar Name',
        AadhaarAddress: 'Aadhaar Address',
        AadhaarYears: 'Aadhaar Years',
        AadhaarDate: 'Aadhaar Date',
        AadhaarNo: 'Aadhaar Number',
        panName: 'Pan Name',
        panNo: 'Pan Number',
        email: 'Email',
        panAddress: 'Pan Address',
        panYears: 'Pan Years',
        panDate: 'Pan Date',
        bankAccountName: 'BankAccount Name',
        bankAccountNo: 'Número de cuenta del banco receptor',
        collectType: 'Forma de recibir préstamo',
        ifscCode: 'IFSC CODE',
        maritalStatus: 'Marital Status',
        spouseName: 'Spouse Name',
        educationLevel: 'Education Level',
        monthlyHouseholdIncome: 'Monthly Household Income',
        personalMonthlyIncome: 'Personal Monthly Income',
        housingType: 'Housing Type',
        residentialAddress: 'Dirección de residencia',
        residentialAddressPIN: 'Residential Address PIN',
        workType: 'Work Type',
        workingAddress: 'Working Address',
        workingAddressPIN: 'Working Address PIN',
        companyName: 'Company Name',
        workIndustry: 'Work Industry',
        workDuty: 'Work Duty',
        workPosition: 'Work Position',
        workYear: 'Work Year',
        payMethod: 'Pay Method',

        contactType: 'Tipo de contacto',
        contactRelationship: 'Relación del contacto',
        contactName: 'Nombre del contacto',
        contactPhone: 'Número de teléfono del contacto',
        firstContact: 'Primer contacto',
        secondContact: 'Segundo contacto',

        aadhaarFront: 'Aadhaar Front',
        aadhaarBack: 'Aadhaar Back',
        panFront: 'PAN Front',
        facePhotoList: 'Fotos de deteción en vivo',

        createTime: 'Fecha de creación',
        reborrowFlag: 'Marca de repetición de préstamo',
        customerBasicInfo: 'Información básica del cliente',
        customerDetailInfo: 'Customers Detail Information',
        customerLinkInfo: 'Información de contacto del usuario',
        customerAadhaarInfo: 'Customers Aadhaar‘s OCR Information',
        customerPanInfo: 'Customers Pan‘s OCR Information',
        customerImageInfo: 'imágenes de usuario',
        age: 'Edad',
        channel: 'Channel',
        productName: 'Product Name',
        epochScore: 'Epoch Score',
        modelScore: 'Model Score',
        customerRiskInfo: 'Customer Risk Info',
        panCardLiveScore: 'PAN CARD FACE SCORE',
        aadhaarCardLiveScore: 'AADHAAR FACE SCORE',

        payoutId: 'ID del canal de dar préstamo',
        stpAccount: 'stp',
        fullName: 'Full name',
        bankName: 'Nombre del banco receptor',
        excelList: ' Download loan information images ',
        orderImages: 'Up payment voucher ',

        companyPhone: 'Company Office Number',
        monthlyIncomeScope: 'monthly income',
        description: 'description',
    }
}