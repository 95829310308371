import customer from './modules/customer';
import order from './modules/order';
import approval from './modules/approval';
import oaSystem from './modules/oaSystem';
import custTrans from './modules/custTrans';
import collectorTotalFollow from './modules/collectorTotalFollow';
import collection from './modules/collection';
import risk from './modules/risk';
import remind from './modules/remind';
import repayReport from './modules/repayReport';
import custOperationCount from './modules/custOperationCount';
import repayOverDueReport from './modules/repayOverDueReport';
import platformCollection from "./modules/platformCollection";
import custServiceWorkOrder from "./modules/custServiceWorkOrder";
import teleMarket from "./modules/teleMarket";
import coupon from './modules/coupon';

export default {
  default: true,
  code: 'zh-CN',
  label: '中文',

  error: '错误',
  tips: '提示',
  reset: '重置',
  search: '查询',
  searchColAll: '查询',
  searchColCur: '查询当天',
  searchColHis: '查询历史',
  check: '验证',
  edit: '编辑',
  more: '更多',
  delete: '删除',
  show: '显示',
  hide: '隐藏',
  confirm: '确定',
  cancel: '取消',
  sort: '排序',
  apply_refuse: '审批拒绝',
  apply_access: '审批通过',
  add: '创建',
  view: '查看',
  repay: '获取还款链接',
  decLateFee: '减免滞纳金',
  createRepayOrder: '生成支付订单',
  createRepayUrl: '生成还款链接',
  repayStatusSync: '还款状态同步',
  payoutStatusSync: '放款状态同步',
  offlineRepay: '线下还款',
  action: '操作',
  userName: '用户名',
  success: '操作成功',
  fail: '操作失败',
  signOut: '退出登录',
  signIn: '登录',
  requestFail: '请求失败，请重试',
  confirmDelete: '确认删除吗？',
  oldPassword: '老密码',
  password: '密码',
  newPassword: '新密码',
  confirmPassword: '确认密码',
  sex: '性别',
  name: '姓名',
  birthday: '生日',
  date: '日期',
  time: '时间',
  man: '男',
  woman: '女',
  email: '邮箱',
  phone: '手机',
  phoneNo: '手机号',
  enter: '请输入',
  select: '请选择',
  enable: '启用',
  disable: '禁用',
  status: '状态',
  remark: '备注',
  exportFile: '导出',
  handle: '处理',
  reCreate: '重建',
  flush: '刷新',
  createReport: '生成报表',
  please_enter_a_positive_integer: "请输入正整数",
  close: '关闭',
  components: {
    total: '总共',
    items: '条',
  },

  home: {
    title: '首页',
    modifyPassword: '修改密码',
    userInfoFail: '请求用户信息失败，请重试',
    signOutInfo: '真的要注销登录吗？',
    userNameTips: '请输入用户名',
    passwordTips: '请输入密码',
    earlyMorning: '早上好',
    morning: '上午好',
    noon: '中午好',
    afternoon: '下午好',
    evening: '晚上好',
    welcome: '欢迎',
    welcomeBack: '欢迎回来',
  },
  customer: customer.zh,
  system: {
    title: '系统管理',
    menuList: {
      menu: '菜单',
      title: '菜单管理',
      index: '序号',
      menuName: '菜单名称',
      icon: '菜单图标',
      type: '菜单类型',
      superiorMenu: '上级菜单',
      menuUrl: '菜单路径',
      menuComponent: '菜单组件',
      sort: '排序',
      action: '操作',
      edit: '编辑',
      delete: '删除',
      add: '添加',
      mainSub: '一级菜单',
      submenu: '子菜单',
      buttonPermission: '按钮/权限',
      nameCn: '中文名称',
      nameEn: '英文名称',
      nameEs: '西语名称',
      nameAr: '阿拉伯语名称',
      nameFr: '法语名称',
      preMenu: '上级菜单',
      menuCode: '授权标识',
    },
    userList: {
      title: '用户列表',
      index: '序号',
      name: '用户名称',
      phone: '手机号',
      mail: '邮箱',
      position: '部门/岗位',
      role: '用户角色',
      app: '应用',
      time: '创建时间',
      status: '状态',
      action: '操作',
      add: '添加',
      edit: '修改',
      password: '密码',
      delete: '删除',
    },
    roleList: {
      title: '角色列表',
      index: '序号',
      type: '角色类型',
      name: '角色名称',
      code: '角色编码',
      description: '角色介绍',
      time: '创建时间',
      action: '操作',
      add: '添加',
      edit: '修改',
      password: '密码',
      delete: '删除',
      permission: '权限配置',
    },
  },
  order: order.zh,
  account: {
    accountDetail: '账户详情',
  },
  postLoan: {
    title: '贷后管理',
  },
  approval: approval.zh,
  oaSystem: oaSystem.zh,
  custTrans: custTrans.zh,
  collection: collection.zh,
  collectorTotalFollow: collectorTotalFollow.zh,
  remind: remind.zh,
  risk: risk.zh,
  repayReport: repayReport.zh,
  custOperationCount: custOperationCount.zh,
  repayOverDueReport: repayOverDueReport.zh,
  other: {
    title: '其他',
  },
  platformCollection: platformCollection.zh,
  custServiceWorkOrder: custServiceWorkOrder.zh,
  teleMarket: teleMarket.zh,
  coupon: coupon.zh,
};
