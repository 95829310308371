export default {
    zh: {
        title: '审批管理',
        performancetitle: '回款报表',
        appSsid: '应用',
        repayCurCount: '到期应还款笔数',
        preCount: '提前还款笔数',
        repayNormalCount: '还款日正常还款笔数',
        extendCount: '展期笔数',
        repayOverCount: '逾期还款',
        overExtendCount: '逾期展期笔数',
        overCount: '逾期未还',
        firstOverDueRate: '首逾',
        firstAmountOverDueRate: '首逾（金额）',
        aoverDueRate: '实时逾期率',
        aAmountoverDueRate: '实时逾期率（金额）',
        perRepayRate:"提前还款率",
        repayNormalRate:"还款日还款率",
        extendCountRate:"展期率",
        repayOverCountRate:"逾期还款率",
        overExtendCountRate:"逾期展期率",
        curDate:"日期",
        requestDate:"日期",
        colbackRate: '催回率',
        T1colbackRate: 'T1催回率',
        T2colbackRate: 'T2催回率',
        T3colbackRate: 'T3催回率',
        T3OvercolbackRate: 'T3+催回率',
        T4colbackRate: 'T4催回率',
        T5colbackRate: 'T5催回率',
        T6colbackRate: 'T6催回率',
        T7colbackRate: 'T7催回率',
        T8colbackRate: 'T8催回率',
        T9colbackRate: 'T9催回率',
        T10colbackRate: 'T10催回率',
        T11colbackRate: 'T11催回率',
        T12colbackRate: 'T12催回率',
        T13colbackRate: 'T13催回率',
        T14colbackRate: 'T14催回率',
        T14OverbackRate: 'T14+催回率',
        repayCurInCount: '入催笔数',
    },
    en: {
        title: 'Approval Management',
        performancetitle: 'Approval Performance',
        appSsid: 'App',
        repayCurCount: 'repayCurCount',
        preCount: 'preCount',
        repayNormalCount: 'repayNormalCount',
        extendCount: 'extendCount',
        repayOverCount: 'repayOverCount',
        overExtendCount: 'overExtendCount',
        overCount: 'overCount',
        firstOverDueRate: 'firstOverDueRate',
        firstAmountOverDueRate: 'firstAmountOverDueRate',
        aAmountoverDueRate: 'aAmountoverDueRate',
        aoverDueRate: 'aoverDueRate',
        perRepayRate:"perRepayRate",
        repayNormalRate:"repayNormalRate",
        extendCountRate:"extendCountRate",
        repayOverCountRate:"repayOverCountRate",
        overExtendCountRate:"overExtendCountRate",
        curDate:"curDate",
        requestDate:"date",
        colbackRate: 'colbackRate',
        T1colbackRate: 'T1colbackRate',
        T2colbackRate: 'T2colbackRate',
        T3colbackRate: 'T3colbackRate',
        T3OvercolbackRate: 'T3OvercolbackRate',
    }
}
