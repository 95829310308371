export default {
  zh: {
    title: '订单管理',
    allTitle: '全部订单',
    loanTitle: '已放款订单',
    pendingTitle: '待放款订单',
    overdueTitle: '逾期订单',
    repayReportTitle: '回款报表1',
    transformingDataExportTitle: '转化数据导出',
    orderNo: '订单编号',
    orderId: '订单ID',
    orderType: '订单类型',
    loanStatus: '放款状态',
    loanAmount: '放款金额',
    loanTime: '放款时间',
    loanPurpose: '借款用途',
    loanNumber: '借款次数',
    settlementStatus: '结清状态',
    settlementTime: '结清时间',
    overdueStatus: '逾期状态',
    applicationTime: '申请时间',
    applicationAmount: '申请金额',
    deadline: '期限',
    applicationDeadline: '申请期限',
    submissionTime: '提交时间',

    orderDetail: '订单详情',
    orderInfo: '订单信息',
    examineCustomerInfo: '审核客户信息',
    examineAddressInfo: '审核地址信息',
    approvalDetail: '审批详情',
    collectionDetail: '催收详情',
    repaymentPlan: '还款计划',
    totalPeriod: '总期数',
    currentPeriod: '当前期数',
    startingTime: '开始时间',
    expireTime: '到期时间',
    totalRepayableAmount: '应还总金额',
    principalPayable: '应还本金',
    overdueDays: '逾期天数',
    forfeitPenalty: '滞纳金',
    status: '状态',
    sxCreditScore: 'INX信用分',
    sxCheatScore: 'INX欺诈分',
    overdueDaysStart: '逾期天数(起)',
    overdueDaysEnd: '逾期天数(止)',

    collectionRecord: '催收记录',
    collectionStaff: '催收人',
    collectionTime: '催收时间',
    collectionTaskStatus: '催收任务状态',
    collectionStatus: '催收状态',
    collectionRemark: '备注',
    followUserName: '跟进人',
    followTime: '跟进时间',
    overdueDay: '逾期天数',
    followResult: '跟进结果',
    followingUpOrder: '跟进中订单',
    repaymentIntention: '还款意愿',


    approvalRecord: '审批记录',
    approvalStaff: '审批人员',
    approvalTime: '审批时间',
    approvalTaskStatus: '审批任务状态',
    approvalStatus: '审核状态',
    approvalRemark: '备注',

    addressBookList: '通讯录列表',
    addressBookName: '联系人姓名',
    addressBookPhoneNo: '联系人姓名',

    lending: '放款',
    isLoan: '是否放款?',
    location: '定位地址',
    q1: '借款人电话是否接通',

    repaymentRecord: '还款记录',
    payOrderNo: '支付订单号',
    payOutId: '支付公司订单号',
    payInId: '内部支付订单号',
    payType: '支付类型',
    payAmount: '支付金额',
    payRecipt: '单据号',
    description: '描述',
    errorDescription: '失败描述',
    recordTime: '记录日期',
    updatedTime: '更新时间',
    channel: '渠道',
    productName: '产品名称',
    isSettle: '是否进行线下还款?',
    isReductionAmt: '是否进行滞纳金减免?',
    isRefuseReductionAmt: '是否拒绝滞纳金申请?',
    branchType: 'branchType',
    curdate: '日期',
    caseLoanName: '贷超名',
    regCount: '注册数',
    cardCustCount: '证件拍摄完成客户数',
    faceCount: '人脸识别客户数',
    baseCount: '基本信息完善客户数',
    linkManCount: '联系人完善客户数',
    applyCount: '申请数',
    bankCardCount: '绑卡客户数',
    approveCount: '通过数',
    loanCount: '放款数',
    overdueOrder: '逾期订单',
    overdueSettleOrder: '逾期未结清订单',
    custType: '用户类型',


    applyAmt: '申请金额',
    repaymentAmount: '应还款金额',
    settleAmt: '已还款金额',
    lateFee: '滞纳金',
    overdueDayC: '逾期天数',
    linkCondition: '通讯录和联系人情况',
    orderNumber: '用户或最近联系人电话号码',
    capital: '应还本金',
    settledCapital: '已还本金',
    settledInterest: '已还利息',
    // lateFee: '滞纳金',
    deductionFee: '减免金额',
    hadDeductionFee: '已减免金额',

    paymentAmt: '实际到账金额',
    paymentTime: '放款时间',
    extendAmoumt: '展期金额',
    oxxoCode: 'oxxo 还款码',
    extendOxxoCode: 'oxxo 展期还款码',
    registerTime: '注册时间',
    appName: 'APP',
    ext: '额外信息',
    ext1: '额外信息1'
  },
  en: {
    title: 'Order Management',
    allTitle: 'All Order',
    loanTitle: 'Loan Order',
    pendingTitle: 'Pending Order',
    overdueTitle: 'Overdue Order',
    orderId: 'Order ID',
    orderNo: 'Order number',
    orderType: 'Order Type',
    loanStatus: 'Loan Status',
    loanAmount: 'Loan Amount',
    loanTime: 'Loan Time',
    loanPurpose: 'Loan Purpose',
    loanNumber: 'Loan Number',
    approvalStatus: 'Approval Status',
    settlementStatus: 'Settlement Status',
    settlementTime: 'Settlement Time',
    overdueStatus: 'Overdue Status',
    applicationTime: 'Application Time',
    applicationAmount: 'Application Amount',
    deadline: 'Deadline',
    applicationDeadline: 'Loan Term',
    submissionTime: 'Submission Time',

    orderDetail: 'Order Detail',
    orderInfo: 'Order Information',
    examineCustomerInfo: 'Examine Customer Infomation',
    examineAddressInfo: 'Examine Address Infomation',
    approvalDetail: 'Approval Detail',
    collectionDetail: 'collection Detail',
    repaymentPlan: 'Repayment Plan',
    totalPeriod: 'Total Period',
    currentPeriod: 'Current Period',
    startingTime: 'Starting Time',
    expireTime: 'Expire Time',
    totalRepayableAmount: 'Total Repayable Amount',
    principalPayable: 'Principal Payable',
    overdueDays: 'Due date',
    forfeitPenalty: 'Forfeit Penalty',
    status: 'Status',
    productName: 'Product Name',

    collectionRecord: 'Collection Record',
    collectionStaff: 'Collection Staff',
    collectionTime: 'Collection Time',
    collectionTaskStatus: 'Task Status',
    collectionStatus: 'Collection Status',
    collectionRemark: 'comment',
    followUserName: 'Name of follow-up person',
    followTime: 'Follow Time',
    overdueDay: 'Due date',
    followResult: 'Customer contact information',
    followingUpOrder: 'Following Up Order',
    repaymentIntention: 'Repayment Intention',

    approvalRecord: 'Approval Record',
    approvalStaff: 'Approval Staff',
    approvalTime: 'Approval Time',
    approvalTaskStatus: 'Approval Task Status',
    approvalRemark: 'Approval Remark',

    addressBookList: 'View Address Book',
    addressBookName: 'Address Book Name',
    addressBookPhoneNo: 'Address Book PhoneNo',

    lending: 'Lending',
    isLoan: 'Confirm the loan?',
    location: 'location',
    isRefuseReductionAmt: 'Refuse apply ?',
    custType: 'cust type',

    applyAmt: 'Apply Amt',
    repaymentAmount: 'Loan Amt',
    settleAmt: 'Repayment amount',
    lateFee: 'Late fee ',
    overdueDayC: 'Due date',
    linkCondition: ' Contact Information Base ',
    orderNumber: 'Phone number of the user or recent contact',
    capital: 'Principal payable',
    settledCapital: 'Principal paid',
    settledInterest: 'Interest paid',
    // lateFee: 'late fee',
    deductionFee: 'Deduction amount',
    hadDeductionFee: 'Had Deduction amount',
    paymentAmt: 'Actual account amount ',
    paymentTime: 'Loan duration',
    extendAmoumt: 'Extension amount',
    oxxoCode: ' Oxxx refund code ',
    extendOxxoCode: 'Oxxo extension repayment Code',
    registerTime: 'Register time',
    appName: 'APP',
    ext: 'ext',
    ext1: 'ext1'
  },
  es: {
    title: 'Order Management',
    allTitle: 'All Order',
    loanTitle: 'Loan Order',
    pendingTitle: 'Pending Order',
    overdueTitle: 'Overdue Order',
    orderId: 'ID de pedido',
    orderNo: 'Número de pedido',
    orderType: 'Tipo de pedido',
    loanStatus: 'Loan Status',
    loanAmount: 'Loan Amount',
    loanTime: 'Tiempo de dar prétamo',
    loanPurpose: 'Loan Purpose',
    loanNumber: 'Loan Number',
    approvalStatus: 'Estado de revisión',
    settlementStatus: 'Estado de liquidación',
    settlementTime: 'Settlement Time',
    overdueStatus: 'Estado de demora',
    applicationTime: 'Application Time',
    applicationAmount: 'Importe solicitado',
    deadline: 'plazo',
    applicationDeadline: 'Período de solicitud',
    submissionTime: 'tiempo de solicitud',

    orderDetail: 'Detalles del pedido',
    orderInfo: 'Información de pedido',
    examineCustomerInfo: 'Examine Customer Infomation',
    examineAddressInfo: 'Examine Address Infomation',
    approvalDetail: 'Approval Detail',
    collectionDetail: 'collection Detail',
    repaymentPlan: 'Plan de pago',
    totalPeriod: 'Número de plazos totales',
    currentPeriod: 'Número de plazos actuales',
    startingTime: 'tiempo de inicio',
    expireTime: 'tiempo de vencimiento',
    totalRepayableAmount: 'Importe total que debe pagar',
    principalPayable: ' Principal que debe pagar',
    overdueDays: 'Número de días de retraso',
    forfeitPenalty: 'costo por retraso',
    status: 'Estado',
    productName: 'Nombre de app',

    collectionRecord: 'Registro de cobros',
    collectionStaff: 'asesor de cobranza',
    collectionTime: 'tiempo de cobro',
    collectionTaskStatus: 'Estado de la tarea de cobro',
    collectionStatus: 'Collection Status',
    collectionRemark: 'comment',
    followUserName: 'Name of follow-up person',
    followTime: 'Follow Time',
    overdueDay: 'Due date',
    followResult: 'Customer contact information',
    followingUpOrder: 'Following Up Order',
    repaymentIntention: 'Repayment Intention',

    approvalRecord: 'Registros de aprobación',
    approvalStaff: 'Aprobador',
    approvalTime: 'Tiempo de aprobación',
    approvalTaskStatus: 'Estado de aprobación',
    approvalRemark: 'Observaciones',

    addressBookList: 'View Address Book',
    addressBookName: 'Address Book Name',
    addressBookPhoneNo: 'Address Book PhoneNo',

    lending: 'Lending',
    isLoan: 'Confirm the loan?',
    location: 'location',
    isRefuseReductionAmt: 'Refuse apply ?',
    custType: 'cust type',

    repaymentRecord: 'Registro de pago',
    payOrderNo: 'Número de orden de pago',
    applyAmt: 'Importe solicitado',
    repaymentAmount: 'Importe que debe pagar',
    settleAmt: 'Importe que ha pagado',
    lateFee: 'costo por retraso',
    payType: 'Tipo de pago',
    payAmount: 'Importe del pago',
    payRecipt: 'Número de factura',
    description: 'Descripción',
    errorDescription: 'Descripción del fallo',
    recordTime: 'Fecha de registro',
    overdueDayC: 'Número de días de retraso',
    linkCondition: ' Contact Information Base ',
    orderNumber: 'Phone number of the user or recent contact',
    capital: 'Principal payable',
    settledCapital: 'Principal paid',
    settledInterest: 'Interest paid',
    // lateFee: 'late fee',
    deductionFee: 'Deduction amount',
    hadDeductionFee: 'Had Deduction amount',

    paymentAmt: 'Importe real recibido',
    paymentTime: 'Tiempo de dar prétamo',
    extendAmoumt: 'Importe de extensión',
    oxxoCode: ' Oxxx refund code ',
    extendOxxoCode: 'Oxxo extension repayment Code',
    registerTime: 'Register time',
    appName: 'APP',
    ext: 'ext',
    ext1: 'ext1'
  }
}
