import zh from './zh';
import en from './en';
import es from './es';
import ar from './ar';
import fr from './fr';
export const languageLabels = [{
        code: zh.code,
        label: zh.label,
        shortName: 'CN',
    },
    {
        code: en.code,
        label: en.label,
        shortName: 'EN',
    },
    {
        code: es.code,
        label: es.label,
        shortName: 'ES',
    },
    {
        code: ar.code,
        label: ar.label,
        shortName: 'AR',
    },
    {
        code: fr.code,
        label: fr.label,
        shortName: 'FR',
    },
]
export default {
    default: zh.code,
    zhCN: zh.code,
    enUS: en.code,
    esUS: es.code,
    frUS: fr.code,
    arUS: ar.code,
    [zh.code]: zh,
    [en.code]: en,
    [es.code]: es,
    [ar.code]: ar,
    [fr.code]: fr,
};