import customer from './modules/customer';
import order from './modules/order';
import approval from './modules/approval';
import collection from './modules/collection';
import risk from './modules/risk';
import remind from './modules/remind';
import repayReport from './modules/repayReport';
import repayOverDueReport from './modules/repayOverDueReport';
import platformCollection from './modules/platformCollection';
import custServiceWorkOrder from './modules/custServiceWorkOrder';
import collectorTotalFollow from './modules/collectorTotalFollow';
import teleMarket from "./modules/teleMarket";
import coupon from './modules/coupon';
export default {
  code: 'es',
  label: 'Español',

  error: 'error',
  tips: 'tips',
  reset: 'Restablecer',
  search: 'consultar',

  searchColAll: 'consultar',
  searchColCur: 'consultar hoy ',
  searchColHis: 'consultar la historia',

  check: 'validar',
  edit: 'editar',
  more: 'más',
  delete: 'Borrar',
  enter: 'introduce',
  select: 'selecciona',
  show: 'Mostrar',
  hide: 'Ocultar',
  confirm: 'confirmar',
  cancel: 'cancelar',
  sort: 'ordenar',
  action: 'Operación',
  add: 'crear',
  view: 'checar',
  success: 'Éxito de la operación',
  fail: 'Operación fallida',
  userName: 'Nombre de usuario',
  signOut: 'Cerrar sesión',
  signIn: 'Iniciar sesión',
  requestFail: 'Solicitud fallida, inténtelo de nuevo',
  confirmDelete: '¿Confirmación del borrado?',
  oldPassword: 'Contraseña antigua',
  password: 'Contraseña',
  newPassword: 'Contraseña nueva',
  confirmPassword: 'Confirmar contraseña',
  createRepayOrder: 'Generar órdenes de pago',
  createRepayUrl: 'Generar un enlace de reembolso',
  repayStatusSync: 'repay status sync',
  payoutStatusSync: 'payout status sync',
  sex: 'sexo',
  name: 'nombre',
  birthday: 'Cumpleaños',
  date: 'fecha',
  time: 'tiempo',
  man: 'hombre',
  woman: 'mujer',
  email: 'correo',
  phone: 'número de teléfono',
  phoneNo: 'número de teléfono',
  enable: 'Activar',
  disable: 'desactivar',
  status: 'estado',
  remark: 'observaciones',
  decLateFee: 'Reducción de las comisiones de demora',
  handle: 'procesar',
  reCreate: 'recrear',
  flush: 'actualizar',
  createReport: 'crear reportes',
  apply_refuse: 'Denegación de solicitud',
  apply_access: 'Aprobación de solicitud',
  repay: 'Obtener el enlace de reembolso',
  offlineRepay: 'Reembolso fuera de línea',
  exportFile: 'Exportar',
  please_enter_a_positive_integer: "Introduzca un número entero positivo",
  close: 'cierre',
  components: {
    total: 'total',
    items: 'item',
  },

  home: {
    title: 'Inicio',
    modifyPassword: 'Cambiar contraseña',
    userInfoFail: 'Error en la solicitud de información de usuario, inténtelo de nuevo',
    signOutInfo: '¿Realmente va a cerrar la sesión',
    userNameTips: 'Introduzca su nombre de usuario',
    passwordTips: 'Introduzca su contraseña',
    earlyMorning: 'buneos dias',
    morning: 'buenos dias',
    noon: 'buenos dias',
    afternoon: 'buenas tardes',
    evening: 'buenas noches',
    welcome: 'bienvenido',
    welcomeBack: 'bienvenido',
  },
  customer: customer.es,
  system: {
    title: 'Administración del sistema',
    menuList: {
      menu: 'Menú',
      title: 'Administración del menú',
      index: 'Número de serie',
      menuName: 'Nombre del menú',
      icon: 'Icono del menú',
      type: 'Tipo de menú',
      superiorMenu: 'Menú principal',
      menuUrl: 'Ruta del menú',
      menuComponent: 'Componentes del menú',
      sort: 'ordenar',
      action: 'operaciones',
      edit: 'editar',
      delete: 'borrar',
      add: 'Añadir',
      mainSub: 'Menú de primer nivel',
      submenu: 'Submenú',
      buttonPermission: 'Botones / Permisos',
      nameCn: 'Nombre chino',
      nameEn: 'Nombre  inglés',
      preMenu: 'Menú principal',
      menuCode: 'Marca de autorización',
    },
    userList: {
      title: 'Lista de usuarios',
      index: 'número de serie',
      name: 'nombre de usuario',
      phone: 'número de móvil',
      mail: 'buzón de correo',
      time: 'hora de creación',
      position: 'departamento/posición',
      role: 'rol del usuario',
      app: 'aplicación',
      status: 'estado',
      action: 'acción',
      add: 'Añadir',
      edit: 'modificar',
      password: 'contraseña',
      delete: 'borrar',
    },
    roleList: {
      title: 'Lista de roles',
      index: 'Número de serie',
      type: 'Tipo de rol',
      name: 'nombre del rol',
      code: 'código del rol',
      description: 'Descripción del rol',
      time: 'hora de creación',
      action: 'Acción',
      add: 'Añadir',
      edit: 'modificar',
      password: 'contraseña',
      delete: 'borrar',
      permission: 'Configuración de permisos',
    },
  },
  order: order.es,
  account: {
    accountDetail: 'Detalles de la cuenta',
  },
  postLoan: {
    title: 'Gestión posterior al crédito',
  },
  approval: approval.en,
  collection: collection.en,
  remind: remind.en,
  risk: risk.en,
  repayReport: repayReport.en,
  repayOverDueReport: repayOverDueReport.en,
  collectorTotalFollow: collectorTotalFollow.en,
  other: {
    title: 'Otros',
  },
  platformCollection: platformCollection.en,
  custServiceWorkOrder: custServiceWorkOrder.es,
  teleMarket: teleMarket.es,
  coupon: coupon.es,
};