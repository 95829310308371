import api from './index'
import { axios } from '@/utils/request'

export function getCustomerList (parameter = {}) {
  return axios({
    url: '/customer/list',
    method: 'post',
    data: parameter
  })
}

export function getTestCustomerList (parameter = {}) {
  return axios({
    url: '/customer/testTist',
    method: 'post',
    data: parameter
  })
}

export function getCustomerQryList (parameter = {}) {
  return axios({
    url: '/customer/infoQryList',
    method: 'post',
    data: parameter
  })
}

export function getCustomerDetail (parameter = {}) {
  return axios({
    url: '/customer/detail',
    method: 'post',
    data: parameter
  })
}

export function checkBankCard (parameter = {}) {
  return axios({
    url: '/risk/bankCheck',
    method: 'post',
    data: parameter
  })
}

export function operationInfoForCust (parameter = {}) {
  return axios({
    url: '/statistics/operationInfoForCust',
    method: 'post',
    data: parameter
  })
}

export function addWritelist(parameter = {}) {
  return axios({
    url: '/customer/addWritelist',
    method: 'post',
    data: parameter
  })
}

export function removeSmsCount(parameter = {}) {
  return axios({
    url: '/customer/removeSmsCount',
    method: 'post',
    data: parameter
  })
}


export function getCustLeavingMessage (parameter = {}) {
  return axios({
    url: '/customer/listCustComplain',
    method: 'post',
    data: parameter
  })
}


export function getCustLeavingMessageDetail (parameter = {}) {
  return axios({
    url: '/customer/viewCustComplain',
    method: 'post',
    data: parameter
  })


}

export function updateCustLeavingMessage (parameter = {}) {
  return axios({
    url: '/customer/replyCustComplain',
    method: 'post',
    data: parameter
  })
}



